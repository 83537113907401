<template>
	<iframe
		title="join-live-webinar"
		loading="lazy"
		id="iframeheadlinenewsfb56-bbc3-98dd-16b1"
		src="https://wdgt01.stockstotrade.com:8084/headlinenews?title=BREAKING NEWS&titleBackground=e31238&titleColor=fff&headline=Join us daily for FREE Algo Trading and Trading Sessions! Click here to join the live webinar&headlineBackground=ccc&headlineColor=000&textLink=https://cfn.stockstotrade.com/sttlive-register"
		width="100%"
		height="46"
		frameborder="0"
		allowtransparency="true"
	></iframe>
</template>

<script setup>
useHead({
	link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
	script: [
		{
			type: "text/javascript",
			innerHTML: `
		//var bn_bnr = bnwgt_settings;

        let bn_hln = "Join us daily for FREE Algo Trading and Trading Sessions! Click here to join the live webinar";

        let bn_lnk = "https://cfn.stockstotrade.com/sttlive-register";

        let ctime_str=(new Date).toLocaleString("en-US",{timeZone:"America/New_York"}),ctime=new Date(ctime_str),end_time_diff=30,start_times=[new Date(ctime_str).setHours(8,0,0),new Date(ctime_str).setHours(12,30,0),new Date(ctime_str).setHours(16,30,0),new Date(ctime_str).setHours(21,0,0)];for(let e of start_times)if(ctime.getTime()>=e&&ctime.getTime()<=e+6e4*end_time_diff){(new SttHeadlineNews).create({width:"100%",title:"BREAKINGS NEWS",titleBackground:"e31238",titleColor:"fff",headline:bn_hln,headlineBackground:"ccc",headlineColor:"000",textLink:bn_lnk,asynchTarget:"ssthdln_001"});break}`
		}
	]
});
</script>
